<template>
  <div id="contact-us">
    <div class="contact-us-menu-title">CONTACT US</div>
    <div class="contact-us-content">
      <div class="contact-us-content-box">
        <div class="contact-us-content-title">CONTACT US!</div>
        <div class="contact-us-content-inputs">
          <b-row cols="1">
            <input
              v-model="contactUs.FirstName"
              class="contact-us-input"
              placeholder="First Name"
            />
            <input
              v-model="contactUs.Email"
              type="email"
              class="contact-us-input"
              placeholder="Email"
            />
            <textarea v-model="contactUs.Text" class="contact-us-input" />
          </b-row>
          <b-row cols="1" cols-md="2" align-h="end" align-v="center">
            <div>
              <b-icon
                @click="$set(contactUs, 'AgreeTerms', !contactUs.AgreeTerms)"
                style="color: white; cursor: pointer"
                :icon="
                  contactUs.AgreeTerms ? 'check-square-fill' : 'square-fill'
                "
              ></b-icon>
              <span style="color: white; padding: 10px"
                >I agree with terms and conditions.</span
              >
            </div>
            <div style="padding: 10px"><div class="send-button">SEND</div></div>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "contact-us",
  data() {
    return {
      contactUs: {
        FirstName: null,
        Email: null,
        Text: null,
        AgreeTerms: false,
      },
    };
  },
  methods: {
    sendMessage() {
      //TODO: validation?
      console.log(this.contactUs);
    },
  },
};
</script>
<style scoped>
.contact-us-menu-title {
  background: url("../assets/themethod_box.png") no-repeat center;
  padding: 10px;
  color: white;
  font-weight: 900;
}
.contact-us-condition .condition-title,
.contact-us-content .content-title-text {
  font-size: 25px;
  font-weight: 900;
  color: var(--jill-contact-us);
}
.contact-us-condition .condition-img {
  width: 80%;
  margin-top: 20px;
}
.contact-us-content {
  background-image: url("../assets/contactus_box_line.png");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}
.contact-us-content-title {
  margin: auto;
  text-align: right;
  position: absolute;
  top: 46%;
  width: 45%;
  left: 0px;
  color: var(--jill-contact-us);
  font-weight: 900;
  font-size: 30px;
}
.contact-us-content-box {
  position: relative;
}
.contact-us-content-inputs {
  width: 45%;
  position: relative;
  padding: 90px 0px 100px;
  margin-left: 51%;
}
.send-button {
  background: url("../assets/send_box.png") no-repeat;
  background-size: 100% 100%;
  padding: 10px 60px;
  margin: auto;
  width: fit-content;
  cursor: pointer;
}
.contact-us-input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  margin: 10px;
}
.contact-us-input::placeholder {
  color: var(--jill-contact-us);
  font-size: 13px;
}
.contact-us-content-inputs .send-button {
  font-size: 19px;
  font-weight: 900;
  color: var(--jill-contact-us);
}
@media (max-width: 400px) {
  .contact-us-content-title {
    text-align: center;
  }
}
</style>