<template>
  <div id="footer">
    <b-row class="footer-body-width" align-h="between">
      <div class="footer-texts-box">
        <div>Jill Health Guide ApS I Copyright 2020. I All rights reserved</div>
        <div>DK 1121 Copenhagen K, Pilestræde 58</div>
        <router-link class="footer-menu" to="PrivacyPolicy">
          <div>Privacy Policy</div>
        </router-link>
      </div>
      <div class="footer-social-icon-box">
        <img class="footer-social-icon" src="@/assets/facebook_icon.png" />
        <img class="footer-social-icon" src="@/assets/twitter_icon.png" />
        <img class="footer-social-icon" src="@/assets/linkedin_icon.png" />
      </div>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
#footer {
  background: #4d4c4d;
}
.footer-body-width {
  width: 80%;
  margin: auto;
}
.footer-texts-box {
  color: white;
  text-align: left;
  line-height: 19px;
  font-weight: 900;
  font-size: 13px;
  margin: 30px 0px 20px;
}
#footer .footer-texts-box .footer-menu {
  text-decoration: none;
  color: white;
}
.footer-social-icon-box {
  margin: auto 0px;
  display: none; /* TODO: ha vannak már linkek vissza tenni! */
}
.footer-social-icon {
  margin: 10px;
}
</style>