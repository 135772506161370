<template>
  <div id="endorsement">
    <div class="endorsement-menu-title">ENDORSEMENT</div>
    <div class="endorsement-content">
      <div class="endorsement-health-box">
        <div class="endorsement-box-title">HEALTH INSURANCE CARE PROGRAMS</div>
        <b-row class="endorsement-content-item" align-h="center">
          <div class="statics-box">
            <img
              class="statics-box-border-img"
              src="@/assets/health_insurance_boxes.png"
            />
            <div class="statics-box-content">
              <div class="statics-box-content-number">4</div>
              <div class="statics-box-content-text">different condition</div>
            </div>
          </div>
          <div class="statics-box">
            <img
              class="statics-box-border-img"
              src="@/assets/health_insurance_boxes.png"
            />
            <div class="statics-box-content">
              <div class="statics-box-content-number">15 992</div>
              <div class="statics-box-content-text">patients</div>
            </div>
          </div>
          <div class="statics-box">
            <img
              class="statics-box-border-img"
              src="@/assets/health_insurance_boxes.png"
            />
            <div class="statics-box-content">
              <div class="statics-box-content-number">2</div>
              <div class="statics-box-content-text">years</div>
            </div>
          </div>
        </b-row>
        <b-row align-h="center" class="endorsement-content-item">
          <div class="percentage-box">
            <div class="percentage-box-title">Diabetes care</div>
            <div class="box-percentage">33%</div>
            <div class="percentage-box-description">
              Increase in the rate of well managed diabetes - 3673 Patients
            </div>
          </div>
          <div class="percentage-box">
            <div class="percentage-box-title">Osteoporosis care</div>
            <div class="box-percentage">12%</div>
            <div class="percentage-box-description">
              Decrease of fall risk in osteoporosis - 3787 Patients
            </div>
          </div>
          <div class="percentage-box">
            <div class="percentage-box-title">COPD care</div>
            <div class="box-percentage">16%</div>
            <div class="percentage-box-description">
              Increase in daily physical activity - 2683 Patients
            </div>
          </div>
          <div class="percentage-box">
            <div class="percentage-box-title">Healthy Individuals</div>
            <div class="box-percentage">92%</div>
            <div class="percentage-box-description">
              Increase in realized change set by the individual health plan -
              5848 Clients
            </div>
          </div>
        </b-row>
      </div>
      <div class="endorsement-patients">
        <div class="endorsement-box-title">
          PATIENTS SUPPORT PROGRAMS FOR PHARMA
        </div>
        <b-row class="endorsement-content-item" align-h="center">
          <div class="statics-box">
            <img
              class="statics-box-border-img"
              src="@/assets/health_insurance_boxes.png"
            />
            <div class="statics-box-content">
              <div class="statics-box-content-number">12</div>
              <div class="statics-box-content-text">different condition</div>
            </div>
          </div>
          <div class="statics-box">
            <img
              class="statics-box-border-img"
              src="@/assets/health_insurance_boxes.png"
            />
            <div class="statics-box-content">
              <div class="statics-box-content-number">17 685</div>
              <div class="statics-box-content-text">patients</div>
            </div>
          </div>
          <div class="statics-box">
            <img
              class="statics-box-border-img"
              src="@/assets/health_insurance_boxes.png"
            />
            <div class="statics-box-content">
              <div class="statics-box-content-number">15</div>
              <div class="statics-box-content-text">years</div>
            </div>
          </div>
        </b-row>
        <b-row align-h="center" class="endorsement-content-item">
          <div class="percentage-box">
            <div class="box-percentage">64%</div>
            <div class="percentage-box-description">
              Increase in the rate of controlled hypertension nationally
            </div>
          </div>
          <div class="percentage-box">
            <div class="box-percentage">2.6x</div>
            <div class="percentage-box-description">
              Increase in treatment completion of the nine months long
              depression treatment
            </div>
          </div>
          <div class="percentage-box">
            <div class="box-percentage">27%</div>
            <div class="percentage-box-description">
              Decrease in COPS related symptoms
            </div>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Endorsement",
};
</script>
<style scoped>
.endorsement-menu-title {
  background: url("../assets/endorsement_box.png") no-repeat center;
  padding: 10px;
  color: white;
  font-weight: 900;
}
#endorsement .endorsement-content .endorsement-content-item {
  margin-top: 30px;
}
.endorsement-box-title {
  color: var(--jill-endorsement);
  font-size: 25px;
  font-weight: 900;
}
.statics-box {
  position: relative;
}
.statics-box-border-img {
  height: 80px;
}
.statics-box-content {
  position: absolute;
  top: 15px;
  width: 100%;
}
.statics-box-content-number {
  color: #f0831c;
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
}
.statics-box-content-text {
  font-size: 21px;
  margin-bottom: -5px;
}
.percentage-box {
  width: 230px;
  padding: 10px 30px;
}
.percentage-box-title {
  font-size: 18px;
  font-weight: 500;
}
.percentage-box .box-percentage {
  color: var(--jill-endorsement);
  font-weight: 900;
  font-size: 20px;
}
.percentage-box-description {
  font-size: 13px;
  line-height: 14px;
}
.endorsement-patients {
  background: url("../assets/patients_support.png") no-repeat center;
  background-position: center;
  padding: 50px 0px;
  margin-top:60px;
  height: fit-content;
}
</style>