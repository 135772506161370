<template>
  <div id="privacy-policy">
    <div class="background-top-hive">
      <div class="background-bottom-hive">
        <div class="privacy-policy-content">
          <div class="paragh-title">Privacy Statement</div>
          <p>EFFECTIVE JANUARY 18, 2021; LAST REVISED DATE JANUARY 18, 2021</p>
          <p>
            Jillhealthguide.com is operated and maintained by Jill Health Guide
            Holding ApS.
          </p>
          <p>
            Jill Health Guide is committed to protecting your privacy and
            providing a safe online experience. This Privacy Policy describes
            our policies and procedures for the collection of personally
            identifiable information (“personal information”) by Jill Health
            Guide in compliance with the General Data Protection Regulation
            (GDPR).
          </p>

          <div class="paragh-title">Privacy Principles</div>
          <p>
            Because your privacy is important to us, we will operate by the
            following principles of Notice, Choice, Security, and Reasonable
            Access.
          </p>
          <div class="paragh-title">NOTICE</div>
          <p>
            Jill Health Guide explicitly asks when it needs any personal
            information from you. You can visit our site and download documents
            without disclosing information such as your name or address.
          </p>
          <p>
            Jill Health Guide collects contact information or demographics
            depending upon purpose or need.
          </p>
          <p>
            If necessary to achieve the purpose for which your personal
            information was submitted, or if you give your consent, Jill Health
            Guide may use your personal information to operate its site and
            provide services, to communicate with you to fulfil requests, to
            allow participation in voluntary activities (such as discussion
            forums), or to compile general demographic statistics.
          </p>

          <div class="paragh-title">CHOICE</div>
          <p>
            Jill Health Guide uses personal information collected on this site
            only for the purpose for which it was submitted by you. At the time
            of collection, we may ask whether you will permit Jill Health Guide
            to use your person- al information in other ways. For example, we
            may ask you whether you would like to receive certain information
            and email from Jill Health Guide or its service partners, or if you
            wish to participate in demographic sur- veys. Unless you
            specifically consent to such use, Jill Health Guide will use your
            information only for the purpose for which it was submitted.
          </p>
          <div class="paragh-title">SECURITY</div>
          <p>
            Jill Health Guide and the third-party service providers it uses have
            estab- lished and maintain reasonable procedures to protect the
            confidentiality, security, and integrity of personal information
            collected by our websites. Jill Health Guide will disclose your
            personal information if required to do so by law or in the
            good-faith belief that such action is necessary to conform with
            applicable laws, or to comply with legal process served on you, Jill
            Health Guide, or the site.
          </p>

          <div class="paragh-title">REASONABLE ACCESS</div>
          <p>
            Jill Health Guide does not currently maintain a comprehensive online
            database of full personal information you may have provided to Jill
            Health Guide through other channels. If you wish to access, review
            or update personal information that you have provided to Jill Health
            Guide (such as a change in mailing address), please provide online
            feedback to office@jillhealthguide.com.
          </p>
          <p>
            If you have any questions about this privacy statement, terms of use
            for this site, or the practices of Jill Health Guide, please provide
            feedback to office@jillhealthguide.com.
          </p>
          <div class="paragh-title">Frequently Asked Questions (FAQ)</div>
          <div>
            <div>
              <b>How does Jill Health Guide use personal information?</b>
            </div>
          </div>
          <p>
            Generally, Jill Health Guide uses your personal information to
            operate the site and to deliver our services. Jill Health Guide may
            use your email address to respond to incoming service requests from
            you.
          </p>

          <div>
            <b
              >How does Jill Health Guide use the demographic data you may
              provide?</b
            >
          </div>
          <p>
            Jill Health Guide may request demographic information or profile
            data when you participate in online surveys. Your provision of this
            information is strictly voluntary and is used to tailor our products
            or services to better meet the needs of Jillhealthguide.com
            visitors. From time to time, Jill Health Guide may also collect
            demographic data as a component of our research undertakings.
            However, we will not gather such data without your knowledge or
            consent.
          </p>

          <div>
            <b
              >Does Jill Health Guide share personal information with other
              companies?</b
            >
          </div>
          <p>Jill Health Guide does not share your personal information.</p>
          <div>
            <b
              >How does this apply to links that you find on the Jill Health
              Guide site?</b
            >
          </div>
          <p>
            Jillhealthguide.com occasionally includes links to other sites. Jill
            Health Guide is not responsible for the privacy practices or the
            content of such websites.
          </p>
          <div>
            <div>
              <b
                >What about cookies and non-personally identifiable
                information?</b
              >
            </div>
          </div>
          <p>
            Cookies are used by many websites to understand how the site is
            being used and to improve the visitor experience in various ways.
            Cookies are small bits of website information stored on your
            computer or device by your browser software. Jillhealthguide.com may
            require you to enable cookies to fully benefit from some services.
            The cookie itself does not contain any personally identifiable
            information, and Jill Health Guide does not use cookies to obtain
            such information.
          </p>
          <p>
            When a visitor comes to Jillhealthguide.com, Jill Health Guide
            servers will request a digital cookie(s) be placed on the visitors
            computer. If the visitors browser is set to accept these cookies,
            Jill Health Guide will track, among other things, which pages were
            visited, the order in which they were visited, and which hyperlinks,
            if any, were clicked. Our analytics partners also record data
            including the visitor’s IP address, operating system (e.g., Windows
            or macOS), browser software (e.g., Safari, Firefox, Internet
            Explorer), and internet service provider. Although these data are
            not personally identifiable information on their own, they can be
            used to determine the approximate geographic location of a visitor’s
            point of connectivity to the internet.
          </p>
          <p>
            Jill Health Guide uses non-personally identifiable information and
            informa- tion obtained through the use of cookies to improve the
            site. By tracking how many people visit Jillhealthguide.com and
            where visitors go on Jill- healthguide.com, we are able to determine
            which parts of the site users like the best, which parts need
            improvement, and what changes should be made. By obtaining this
            information, we are able to continually improve Jillhealthguide.com
            and bring you the best online experience possible.
          </p>
          <div class="paragh-title">How can you contact us?</div>
          <p>
            If you have any questions about this privacy statement, Jill Health
            Guide’s practices, or your experience with our web presence, please
            provide online feedback to office@jillhealthguide.com.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
};
</script>
<style scoped>
#privacy-policy {
  background: #f2f2f2;
}
#privacy-policy .background-top-hive {
  background-image: url("../assets/hive_motive.png");
  background-repeat: no-repeat;
  background-position-x: -250px;
  background-position-y: -60px;
}
#privacy-policy .background-bottom-hive {
  background-image: url("../assets/hive_motive_bottom_right.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
#privacy-policy .privacy-policy-content {
  text-align: left;
  column-count: 2;
  width: 80%;
  margin: auto;
  padding: 80px 0px 40px;
  font-size: 14px;
}
#privacy-policy .paragh-title {
  color: orange;
  font-weight: 900;
  font-size: 25px;
}
@media (max-width: 1000px) {
  #privacy-policy .privacy-policy-content {
    column-count: 1;
  }
}
</style>