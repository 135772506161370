<template>
  <div id="home">
    <img class="home-header" src="@/assets/home_header.svg" />
    <what-is-it style="padding-top: 50px" />
    <the-method style="padding-top: 50px" />
    <endorsement style="padding-top: 50px" />
    <our-expertise style="padding-top: 50px" />
    <products style="padding-top: 50px" />
    <!-- <contact-us style="padding-top: 50px" /> -->
  </div>
</template>

<script>
import ContactUs from "../components/ContactUs.vue";
import Endorsement from "../components/Endorsement.vue";
import OurExpertise from "../components/OurExpertise.vue";
import Products from "../components/Products.vue";
import TheMethod from "../components/TheMethod.vue";
import WhatIsIt from "../components/WhatIsIt.vue";
export default {
  components: {
    WhatIsIt,
    TheMethod,
    Endorsement,
    OurExpertise,
    Products,
    ContactUs,
  },
  name: "Home",
};
</script>
<style scoped>
.home-header {
  width: 100%;
}
</style>