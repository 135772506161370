<template>
  <div id="app">
    <Header />
    <router-view class="website-body-content" />
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: { Header, Footer },
};
</script>
<style>
:root {
  --jill-what-is-it: #72bb45;
  --jill-the-method: #e2893a;
  --jill-endorsement: #7a3988;
  --jill-our-expertise: #e22a32;
  --jill-products: #26a5ce;
  --jill-contact-us: #e2893a;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0px;
}
.website-body-content {
  margin-top: 10px;
}
.page-width {
  width: 80%;
  margin: auto;
}
#app .website-body-content .row,
#app #footer {
  margin: 0px;
}
</style>
