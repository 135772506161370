<template>
  <div id="our-expertise">
    <div class="our-expertise-menu-title">OUR EXPERTISE</div>
    <div class="content-25-years">
      <div><img src="@/assets/25years_box.png" /></div>
      <div class="content-25-years-body">
        <div class="content-25-years-number">25</div>
        <div class="content-25-years-text">YEARS</div>
      </div>
      <div class="content-25-years-description">
        of medical research and development to gain the knowledge needed to
        scale the key experiences.
      </div>
    </div>
    <b-row cols="1" cols-md="2" class="our-expertise-table">
      <div class="our-expertise-table-element">
        <div class="table-element-title">
          Extensive global research of health behaviour
        </div>
        <div class="table-element-description">
          Data collection, ethnography, observations, adapted psychometric and
          psychotherapy methodologies.
        </div>
      </div>
      <div class="our-expertise-table-element">
        <div class="table-element-title">Advisors</div>
        <div class="table-element-description">
          Global network of specialists in client centric marketing and client
          centric health services.
        </div>
      </div>
      <div class="our-expertise-table-element">
        <div class="table-element-title">
          Development of out-patient cardiac rehabilitation program
        </div>
        <div class="table-element-description">
          Based on wearable ECG device, mobile app, central database digital
          ecosystem and health management.
        </div>
      </div>
      <div class="our-expertise-table-element">
        <div class="table-element-title">Expert network</div>
        <div class="table-element-description">
          Collaborative ecosystem of health experts, data experts, marketing and
          ommunication experts, lawyers and health insurance experts.
        </div>
      </div>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "OurExpertise",
};
</script>
<style scoped>
.our-expertise-menu-title {
  background: url("../assets/our_expertise_box.png") no-repeat center;
  padding: 10px;
  color: white;
  font-weight: 900;
}
.content-25-years {
  /* background: url("../assets/25years_box.png") no-repeat center; */

  position: relative;
  width: fit-content;
  max-width: 600px;
  margin: 15px auto;
}
.content-25-years-body {
  position: absolute;
  top: 0px;
  width: 100%;
}
.content-25-years-number {
  font-size: 90px;
  font-weight: 900;
  line-height: 70px;
  margin-top: 0px;
  color: var(--jill-our-expertise);
}
.content-25-years-text {
  font-size: 30px;
  font-weight: 900;
  color: #646166;
}
.content-25-years-description {
  font-size: 20px;
  padding: 20px;
}
#our-expertise .our-expertise-table {
  width: 80%;
  margin: auto;
}
.our-expertise-table-element {
  text-align: left;
  padding: 30px 50px;
  font-size: 14px;
}
.our-expertise-table-element .table-element-title {
  color: var(--jill-our-expertise);
  font-weight: 800;
}
.our-expertise-table-element .table-element-description {
  line-height: 16px;
}
</style>