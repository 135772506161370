<template>
  <div id="the-method">
    <div class="the-method-menu-title">THE METHOD</div>
    <div class="guide-people page-width">
      <div class="the-method-description">
        GUIDE PEOPLE TO HEALTH CHANGES STEP BY STEP
      </div>
      <div class="page-width">
        <b-row style="padding-top: 20px" cols="1" cols-md="2" cols-lg="4">
          <div class="the-method-row-elements">
            <div><img src="@/assets/personal_health_guides.png" /></div>
            <div class="row-element-title">Personal health guides</div>
            <div class="row-element-description">
              Through a long-term relationship, our health guides co-create a
              personalised health journey together with the client
            </div>
          </div>
          <div class="the-method-row-elements">
            <div><img src="@/assets/evidance_based_medical_advice.png" /></div>
            <div class="row-element-title">Evidance based medical advice</div>
            <div class="row-element-description">
              Our holistic methods are built on solid and proven medical
              research about specific health situations
            </div>
          </div>
          <div class="the-method-row-elements">
            <div><img src="@/assets/human_centered_approach.png" /></div>
            <div class="row-element-title">A human-centered approach</div>
            <div class="row-element-description">
              We approach each individual from their own unique situation and
              help them create a new narrative towards a healthier life
            </div>
          </div>
          <div class="the-method-row-elements">
            <div><img src="@/assets/personal_health_guides.png" /></div>
            <div class="row-element-title">
              Learn from multiple data sources
            </div>
            <div class="row-element-description">
              The human-centred approach is supported by the digital ecosystem,
              providing a rich set of data. We measure progress and learn
              through feedback loops
            </div>
          </div>
        </b-row>
      </div>
    </div>
    <div class="understanding page-width">
      <div class="the-method-description">
        UNDERSTANDING THE PERSON BEHIND THE CONDITION
      </div>
      <div class="example-title">Example with psoriasis</div>
      <div>
        <img class="example-img" src="@/assets/example_with_psoriasis.png" />
      </div>
    </div>
    <div class="the-method-footer">
      <div class="page-width">
        <!-- TODO: képeket egységes méretre vágni -->
        <b-row cols="1" cols-sm="2" cols-lg="3">
          <div class="the-method-footer-table-element">
            <div class="table-element-img">
              <img src="@/assets/split_heart.png" />
            </div>
            <div class="table-element-text">
              <div class="title">Split heart</div>
              <div>After myocardial infarction</div>
            </div>
          </div>
          <div class="the-method-footer-table-element">
            <div class="table-element-img">
              <img src="@/assets/lossofdignity.png" />
            </div>
            <div class="table-element-text">
              <div class="title">Loss of dignity</div>
              <div>Psoriasis</div>
            </div>
          </div>
          <div class="the-method-footer-table-element">
            <div class="table-element-img">
              <img src="@/assets/limitationofmovement.png" />
            </div>
            <div class="table-element-text">
              <div class="title">Limitation of movement</div>
              <div>COPD</div>
            </div>
          </div>
          <div class="the-method-footer-table-element">
            <div class="table-element-img">
              <img src="@/assets/lossofimpact.png" />
            </div>
            <div class="table-element-text">
              <div class="title">Loss of impact on own life</div>
              <div>Mild and moderate depression</div>
            </div>
          </div>
          <div class="the-method-footer-table-element">
            <div class="table-element-img">
              <img src="@/assets/surpression.png" />
            </div>
            <div class="table-element-text">
              <div class="title">Suppression of own needs</div>
              <div>Health at work</div>
            </div>
          </div>
          <div class="the-method-footer-table-element">
            <div class="table-element-img">
              <img src="@/assets/lostinnumbers.png" />
            </div>
            <div class="table-element-text">
              <div class="title">Lost in numbers</div>
              <div>After annual health checks</div>
            </div>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TheMethod",
};
</script>
<style scoped>
.the-method-menu-title {
  background: url("../assets/themethod_box.png") no-repeat center;
  padding: 10px;
  color: white;
  font-weight: 900;
}
.the-method-description {
  font-size: 25px;
  font-weight: 900;
  color: var(--jill-the-method);
}
.the-method-row-elements {
  padding: 10px;
}
.row-element-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin: 20px 0px;
}
.row-element-description {
  font-size: 13px;
  line-height: 13px;
}
.example-title {
  font-size: 20px;
}
.example-img {
  width: 70%;
  margin-bottom: 40px;
}
.the-method-footer {
  background: url("../assets/picto_background.png");
  background-size: 100%;
}
.the-method-footer .page-width {
  width: 60%;
  padding: 20px;
}
.the-method-footer-table-element {
  display: flex;
  padding: 5px;
}
.table-element-img {
  padding: 0px 15px;
}
.table-element-text {
  text-align: left;
  font-size: 13px;
  margin: auto 0px;
}
.table-element-text .title {
  font-weight: 900;
}
</style>