<template>
  <div id="products">
    <div class="products-menu-title">PRODUCTS</div>
    <div class="products-condition">
      <div class="condition-title">CONDITION SPECIFIC HEALTH SERVICE LINES</div>
      <div class="condition-img-box">
        <img
          class="condition-img"
          src="@/assets/condition_specific_health_service_pic.png"
        />
      </div>
    </div>
    <div class="products-areas">
      <div class="products-areas-box">
        <div class="products-areas-list">
          <div class="products-areas-title">AREAS</div>
          <b-row cols="1" cols-sm="2" cols-md="3" align-v="start">
            <li>Burn-out prevention and care</li>
            <li>Depression</li>
            <li>Heart attack rehabilitation</li>
            <li>Hypertension</li>
            <li>Psoriasis</li>
            <li>Panic disorder</li>
            <li>Asthma</li>
            <li>COPD</li>
            <li>Metabolic syndrome</li>
            <li>Somatic symptom disorders</li>
            <li>Multiple sclerosis</li>
            <li>Osteoporosis</li>
            <li>Rheumatoid arthritis</li>
            <li>Corporate health management</li>
            <li>Individual health management</li>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Products",
};
</script>
<style scoped>
.products-menu-title {
  background: url("../assets/products_box.png") no-repeat center;
  padding: 10px;
  color: white;
  font-weight: 900;
}
.products-condition .condition-title,
.products-areas .areas-title-text {
  font-size: 25px;
  font-weight: 900;
  color: var(--jill-products);
}
.products-condition .condition-img {
  width: 80%;
  margin-top: 20px;
}
.products-areas {
  background-image: url("../assets/aresas_box_line.png");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}
.products-areas-title {
  margin: auto;
  padding-right: 100px;
  position: absolute;
  top: 45%;
  left: -180px;
  color: var(--jill-products);
  font-weight: 900;
  font-size: 30px;
}
.products-areas-list {
  width: 45%;
  position: relative;
  padding: 90px 0px 100px;
  margin-left: 51%;
}
.products-areas-list li {
  padding: 5px 10px;
  font-size: 13px;
  text-align: left;
}
</style>