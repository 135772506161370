<template>
  <div id="what-is-it">
    <div class="what-is-it-menu-title">WHAT IS IT?</div>
    <div class="hive-motive-background">
      <div class="what-is-it-on-demand page-width">
        <div class="what-is-it-description">ON DEMAND HEALTH ASSISTANCE</div>
        <b-row cols="1" cols-lg="2" class="on-demand-content">
          <img class="on-demand-img" src="@/assets/on_demand_health_pic.png" />
          <div class="on-demand-text">
            A health support platform that is a unique combination of art and
            science with unified development inmedical science, industry 4.0
            technology, positive psychology and collaborative networks using
            behavioural science, medical therapy, art therapy, one-to-one
            guidance, data science, quantitative and qualitative feedback and
            online experience sharing applications.
            <br /><br />Through our unique method we are helping people to get
            back on their feet.
          </div>
        </b-row>
        <div class="on-demand-subdescription">
          In the future a perfect health industry can only be achieved when it
          includes and accepts humans as irrational. It should recognize people
          as a whole, and not as a result of their functions.
          <div class="on-demand-footer">
            We are helping people to get back on their feet.
          </div>
        </div>
      </div>
    </div>
    <div class="what-is-it-runner">
      <div class="page-width">
        <div class="what-is-it-description">
          THE STORY THAT NEVER ENDS IS THE STORY OF LIFE
        </div>
        <img class="story-of-life-img" src="@/assets/storyoflife_pic.png" />
        <div class="story-of-life-text">
          When a critical life event happens or unbearable discomfort is
          accumulated,<br />
          to reformulate the story of your life is to create a new chapter with
          doable modifications.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhatIsIt",
};
</script>
<style scoped>
.what-is-it-menu-title {
  background: url("../assets/whatisit_box.png") no-repeat center;
  padding: 10px;
  color: white;
  font-weight: 900;
}
.what-is-it-description {
  font-size: 25px;
  font-weight: 900;
  color: var(--jill-what-is-it);
}
.what-is-it-runner {
  background-image: url("../assets/runner_background.png");
  background-position: center;
  padding: 20px;
}
.story-of-life-img {
  margin: 50px 0px;
  width: 100%;
}
.story-of-life-text {
  text-align: center;
  color: black;
  font-size: 21px;
  margin: auto;
}
/* .on-demand-content {
  display: flex;
  margin: 50px auto;
} */
.hive-motive-background {
  background: url(../assets/hive_motive_bottom_right.png) no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 300px;
}
.on-demand-img {
  padding: 20px;
  width: 100%;
  height: 100%;
}
.on-demand-text {
  padding: 20px;
  text-align: left;
  font-size: 22px;
  color: black;
}
.on-demand-subdescription {
  font-size: 20px;
  color: black;
  margin: auto;
}
.on-demand-footer {
  color: var(--jill-what-is-it);
  font-weight: 900;
  padding: 30px 0px;
}
</style>