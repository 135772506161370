<template>
  <div class="header-line-box">
    <router-link class="header-home-logo" to="/#home"
      ><img class="logo" src="@/assets/logo.png"
    /></router-link>
    <div class="vertical-align-middle header-nav-items-box-scroll">
      <router-link class="header-menu-item-link nav-item" to="/#what-is-it">
        <div class="header-menu-item">WHAT IS IT?</div>
      </router-link>
      <router-link class="header-menu-item-link nav-item" to="/#the-method">
        <div class="header-menu-item">THE METHOD</div>
      </router-link>
      <router-link class="header-menu-item-link nav-item" to="/#endorsement">
        <div class="header-menu-item">ENDORSEMENT</div>
      </router-link>
      <!-- <router-link class="header-menu-item-link nav-item" to="/#contact-us">
        <div class="header-menu-item">CONTACT US</div>
      </router-link> -->
      <router-link class="header-menu-item-link nav-item" to="/#products">
        <div class="header-menu-item">PRODUCTS</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
};
</script>
<style scoped>
.header-line-box {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  width: 100%;
  background: white;
  white-space: nowrap;
  overflow-x: auto;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.2);
}
.header-home-logo {
  margin: auto 10px;
}
.header-home-logo .logo {
  height: 30px;
}
.header-menu-item-link {
  text-decoration: none;
}
.header-menu-item {
  padding: 10px 20px;
  color: orange;
  font-weight: 900;
  vertical-align: bottom;
  width: fit-content;
}
.header-menu-item:hover, .header-menu-item-link.router-link-exact-active .header-menu-item {
  color: white;
  background: orange;
}
.header-nav-items-box-scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
* {
  box-sizing: border-box;
}
header,
nav {
  font-size: 0;
}
.header-menu-item-link {
  font-size: 14px;
}
.header-home-logo,
.header-menu-item-link,
.vertical-align-middle {
  display: inline-block;
  vertical-align: middle;
}
.header-nav-items-box-scroll::-webkit-scrollbar {
  display: none;
}
</style>